@import url('https://fonts.googleapis.com/css?family=Raleway');
@import url('https://fonts.googleapis.com/css?family=Poppins');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Raleway', 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.stack {
  height: 2.4rem;
  box-sizing: border-box;
  padding: 0.4rem .6rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  background: #E1E8EF;

  color: #000;
  font-family: Raleway;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 200% */
  letter-spacing: 0.015rem;
}

.navlink-navcard {
  border-radius: 1.25rem;
  text-decoration: none;
  width: 5rem;
  height: 4rem;
  padding-top: 1rem;
  display: block;
  color: #F2F5F9;
  text-align: center;
  background-color: #E1E8EF;
}

.nav-burger {
  margin: .8rem .6rem 0 .6rem;
  border-radius: 1.25rem;
  text-decoration: none;
  width: 5rem;
  height: 4rem;
  padding-top: 1rem;
  display: block;
  color: #F2F5F9;
  text-align: center;
  background-color: #E1E8EF;
  right: 0rem;
  position: fixed;
}

.nav-burger.active {
  right:0;
  position: relative;
}

.sidebar {
  z-index: 1;
  position: fixed;
  width: 6.25rem;
  height: 6.25rem;
  right: -10rem;
  /*margin: 0 5vw 0 0;*/
  border-radius: 1.25rem;
  background: #fafbfd;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  transition: 0.22s;
  visibility: hidden;
}

.sidebar.active {
  height: 32.25rem;
  right:0;
  visibility: visible;
}

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/
