.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.centerdiv{
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidescroll {
    margin:1rem 0 .5rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    overflow-y: auto;
    /*gap: .2rem;*/
    box-sizing: content-box;
}
